<template>
  <div class="resume-index" v-loading="loading">
    <el-card>
    <div class="c-title">已购简历列表</div>
    <div class="selectBody">
      <div class="keyword">
        <el-input v-model="input" clearable placeholder="搜索人才信息">
          <el-button slot="append" icon="el-icon-search" @click="selectKeyword()"></el-button>
        </el-input>
      </div>
      <ul>
        <li>
          <el-select v-model="selectListVal.gender" size='small' clearable placeholder="性别" class="smallitem">
            <el-option v-for="(item, index) in genderList" :key="index" :label="item" :value="index">
            </el-option>
          </el-select>
        </li>
        <li>
          <el-select v-model="selectListVal.height" clearable size='small' @change="heightStatus" placeholder="身高" class="smallitem">
            <el-option v-for="(item, index) in heightList" :key="index" :label="item" :value="index">
            </el-option>
          </el-select>
        </li>
        <li>
          <el-select v-model="ageInputVal" size='small' clearable @change="ageStatus" placeholder="年龄" class="smallitem">
            <el-option v-for="(item, index) in ageList" :key="index" :label="item"
                  :value="{ value: index, label: item }">
            </el-option>
          </el-select>
        </li>
        <li>
          <el-select v-model="selectListVal.education_level" size='small' @change="educationStatus" clearable placeholder="学历" class="smallitem">
            <el-option v-for="(item, index) in educationList" :key="index" :label="item" :value="index">
            </el-option>
          </el-select>
        </li>
        <li>
          <el-select v-model="selectListVal.army_service_history" size='small' clearable @change="armyHistoryStatus" placeholder="兵役史" class="smallitem">
            <el-option v-for="(item, index) in armyHistoryList" :key="index" :label="item" :value="index">
            </el-option>
          </el-select>
        </li>
        <li>
          <el-select v-model="selectListVal.cert" size='small' clearable multiple @change="certStaffStatus" placeholder="证书" class="smallitem">
            <el-option v-for="(item, index) in certNameList" :key="index" :label="item.name" :value="index">
            </el-option>
          </el-select>
        </li>
        <li>
          <el-select v-model="selectListVal.user_auth_state" size='small' clearable @change="authStatus" placeholder="实名认证" class="smallitem">
            <el-option v-for="(item, index) in realList" :key="index" :label="item" :value="index">
            </el-option>
          </el-select>
        </li>
        <li>
          <el-select v-model="selectListVal.allocation" size='small' clearable @change="allocationStatus" multiple placeholder="期望职位类型" class="smallitem">
            <el-option v-for="(item, index) in allocationList" :key="index" :label="item.unit_nature" :value="index">
            </el-option>
          </el-select>
        </li>
      </ul>      
    </div>
      <!-- show-header 是否显示表头 -->
      <el-table
        ref="multipleTable"
        :data="stdInfo"
        tooltip-effect="dark"
        :show-header="false"
        border
        @row-click="goStdDetails"
      >
        <el-table-column>
          <template slot-scope="scope">
            <el-row class="i-list">
              <el-col :span="3" class="l-col">
                <el-image
                  :src="imageSrc(scope.row.user_avatar, scope.row.gender)"
                ></el-image>
                <div class="Real" v-if="scope.row.user_auth_state == 3">
                  <span>已实名</span>
                </div>
              </el-col>
              <el-col :span="17" class="l-col">
                <div class="i-info">
                  <div class="i-name">{{ scope.row.user_nickname }}</div>
                  <el-row class="i-content">
                    <el-col :span="12">
                      <ul class="c-info">
                        <!-- 性别 -->
                        <li class="i-item">
                          {{ scope.row.gender | genderFormat }}
                        </li>
                        <li class="i-item" v-if="scope.row.id_cart_num">{{ scope.row.id_cart_num }}岁</li>
                        <!-- 身高 -->
                        <li class="i-item" v-if="scope.row.height">{{ scope.row.height }}cm</li>
                        <li class="i-item" v-if="scope.row.min_salary_range == 0 || scope.row.min_salary_range == null ">面议</li>
                        <li class="i-item" v-else>{{scope.row.min_salary_range}}-{{scope.row.max_salary_range}}k</li>
                        <li class="i-item" v-if=" scope.row.education_level">{{ scope.row.education_level }}</li>
                        <li class="i-item" v-if="scope.row.job_wanted == 1">正在找工作</li>
                        <li class="i-item" v-if="scope.row.job_wanted == 0">暂不找工作</li>                       
                      </ul>
                    </el-col>
                    <el-col :span="12">
                      <!-- <div class="i-exp mt5">
                        2021.03-2023.08 十二方研究院*程序员
                      </div> -->
                    </el-col>
                  </el-row>
                </div>
              </el-col>
              <el-col :span="4" class="l-col">
                <div class="p-text">
                  <i class="el-icon-phone-outline"></i>
                  <span class="p-phone">{{ scope.row.user_phone }}</span>
                </div>
              </el-col>
            </el-row>
            <div class="certName"><span v-for="(item,index) in scope.row.certName" :key="index" class="cert">{{item}}</span></div>
          </template>
        </el-table-column>
      </el-table>
      <el-row class="l-pagination">
        <Pagination
          :page.sync="page"
          :limit.sync="limit"
          :total="count"
          @getList="getStudentList"
        />
      </el-row>
    </el-card>
  </div>
</template>

<script>
  import {
    getBuyStdInfoApi
  } from '@/api/talentPool'
  import {
    selectInitJobApi
  } from '@/api/home'
  import Pagination from '@/components/Pagination/index.vue'
  export default {
    name: "resume-index",
    components:{
        Pagination
    },
    data() {
      return {
        stdInfo: [],
        loading: false,
        page: 1,
        limit: 5,
        count: 0,
        input: '',
        selectListVal: {
          gender: '',
          height: '',
          education_level: '',
          army_service_history: '',
          minDay: '',
          maxDay: '',
          cert: [],
          user_auth_state: '',
          allocation: [],
          keyword: '',
        },
        ageInputVal: '',
        realList: {
          "0": "未认证",
          "3": "已认证",
        },
        genderList: {
          "0": "女",
          "1": "男",
        },
        heightList: {
          "155": "155cm以上",
          "160": "160cm以上",
          "165": "165cm以上",
          "170": "170cm以上",
          "175": "175cm以上",
          "180": "180cm以上",
        },
        ageList: {
          "0": '不限',
          "1": "16-20岁",
          "2": "21-30岁",
          "3": "31-40岁",
          "4": "41-50岁",
          "5": "51-60岁",
          "6": "60岁以上",
        },
        armyHistoryList: {
          "0": "否",
          "1": "是",
        },
        educationList: {}, //学历列表
        certNameList: {}, //证书列表
        allocationList: {}, //期望职位类型
        ageMapList: [{
            id: '0',
            ageVal: []
          },
          {
            id: '1',
            ageVal: [16, 20]
          },
          {
            id: '2',
            ageVal: [21, 30]
          },
          {
            id: '3',
            ageVal: [31, 40]
          },
          {
            id: '4',
            ageVal: [41, 50]
          },
          {
            id: '5',
            ageVal: [51, 60]
          },
          {
            id: '6',
            ageVal: [61, 70]
          }
        ],
      }
    },
    filters: {
      genderFormat(val) {
        if (val == 1) {
          return val = '男'
        } else if (val == 2) {
          return val = '未知'
        } else {
          return val = '女'
        }
      }
    },
    watch: {
      selectListVal: {
        handler(val) {
          this.selectListVal = val
          this.page = 1
          this.getStudentList()
        },
        deep: true
      }
    },
    created() {
      this.getStudentList()
      this.getSelectInit()
    },
    methods: {
      //下拉数据初始化
      async getSelectInit() {
        const {
          data: res
        } = await selectInitJobApi()
        if (res.status != 10000) {
          return this.$message.error('下拉列表数据初始化失败')
        }
        this.certNameList = res.result.certName
        this.educationList = res.result.education
        this.allocationList = res.result.allocation
      },

      async getStudentList() {
        this.loading = true
        const {
          data: res
        } = await getBuyStdInfoApi(this.selectListVal, this.page, this.limit)
        this.stdInfo = res.result.list
        this.count = res.result.count
        this.loading = false
      },
      genderStatus(val) {
        this.selectListVal.gender = val
      },
      heightStatus(val) {
        this.selectListVal.height = val
      },
      educationStatus(val) {
        this.selectListVal.education_level = val
      },
      armyHistoryStatus(val) {
        this.selectListVal.army_service_history = val
      },
      certificateStatus(val) {
        this.selectListVal.certificate = val
      },
      certStaffStatus(val){
        this.selectListVal.cert=val
      },
      certFireStatus(val){
        this.selectListVal.cert_fire=val
      },
      ageStatus(params) {
        const {
          value,
          label
        } = params
        this.ageInputVal = label
        let val = value
        let ageVal = ''
        this.ageMapList.forEach(item => {
          if (item.id == val) {
            ageVal = item.ageVal
          }
        })
        this.selectListVal.minDay = ageVal[0]
        this.selectListVal.maxDay = ageVal[1]
      },
      authStatus(val){
        this.selectListVal.user_auth_state = val
      },
      allocationStatus(val){
        this.selectListVal.allocation = val
      },

      selectKeyword(val){
        this.selectListVal.keyword = this.input
        this.getStudentList()
      },

      imageSrc(imgUrl, gender) {
        if (imgUrl) {
          return imgUrl
        } else if (imgUrl == null && gender == 0) {
          return require('../../assets/images/woman.jpg')
        } else if (imgUrl == null && gender == 1) {
          return require('../../assets/images/man.jpg')
        }
      },
      //跳转至学员详情页
      goStdDetails(row) {
        this.$router.push(`/pc/resume/detail?id=${row.user_id}`)
      }
    }

  }

</script>

<style lang="less" scoped>
.resume-index {
    .c-title {
    height: 40px;
    line-height: 40px;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 20px;
    border-bottom: 1px solid #e4e7ed;
    text-align: center;
  }
  .i-list {
    display: flex;
    padding: 20px;

    .el-image {
      width: 50px;
      height: 50px;
      border-radius: 100%;
      vertical-align: middle;
      margin: -3px 0 0 10px;
      border: none;
    }

    .i-name {
      font-weight: 600;
    }

    .i-content {
      display: flex;

      .c-info {
        display: flex;
        color: #8d92a1;
        margin-top: 10px;
        font-size: 12px;

        .i-item {
          // border-left: 1px solid #8d92a1;
          padding-left: 10px;
          // height: 10px;
          // line-height: 12px;
        }

        .i-item:first-child {
          padding-left: 0px;
          border-left: none;
        }
      }

      .i-exp {
        margin-bottom: 5px;
        font-size: 13px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .l-col {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .el-button {
      background: #409eff;
      color: #fff;
    }
  }

  /deep/ .el-dialog__body {
    padding: 0;
  }

  /deep/.el-dialog__wrapper {
    top: 25%;
  }

  .l-pagination {
    text-align: center;
    margin-top: 20px;
  }

  .p-text {
    font-size: 16px;
    font-weight: 600;
    color: #ff7125;

    .p-phone {
      margin-left: 5px;
    }
  }

  .certName{
    padding: 0 20px 20px;

    .cert{
      display: inline-block;
      background: #f56c6c;
      color: #fff;
      padding: 5px 8px;
      border-radius: 8px;
      font-size: 12px;
      margin: 0 6px 6px 0;
    }
  }

  .Real span{
    display: inline-block;
    padding: 1px 5px;
    color: #0092ff;
    font-size: 12px;
    line-height: 1.5;
    border: 1px solid #EBEEF5;
    margin: 5px 0 0 8px;
  }
}
.keyword{
  padding-bottom: 20px;
}
.selectBody{
  margin-bottom: 10px;
}
.selectBody li{
  display: inline-block;
  margin: 0 15px 10px 0;
}
.smallitem{
  width: 130px;
}
</style>
